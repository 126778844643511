<script setup lang="ts">
import { ref } from 'vue';

const SITE_PASSWORD = import.meta.env.VITE_SITE_PASSWORD;

// If SITE_PASSWORD is falsy (empty string, null, or undefined), do not prompt for password.
// Otherwise, compare the stored password in localStorage with SITE_PASSWORD.
const isVisible = ref(
  !!SITE_PASSWORD && localStorage.getItem('stored-pw') !== SITE_PASSWORD
);
const password = ref('');
const passwordError = ref<string | undefined>(undefined);

const validatePassword = () => {
  passwordError.value = undefined;
  if (password.value === SITE_PASSWORD) {
    localStorage.setItem('stored-pw', password.value);
    isVisible.value = false;
  } else {
    passwordError.value = 'Incorrect password';
  }
};
</script>

<template>
  <BalModal
    :show="isVisible"
    :canHide="false"
    title="Enter the password to gain access to BurrBear"
  >
    <BalVStack align="stretch" spacing="md">
      <BalTextInput
        v-model="password"
        name="password"
        type="password"
        placeholder="Password"
      />
      <BalBtn :block="true" @click="validatePassword">Unlock</BalBtn>
      <BalAlert v-if="passwordError" type="error" title="">
        {{ passwordError }}
      </BalAlert>
    </BalVStack>
  </BalModal>
</template>